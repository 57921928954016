<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        <el-select v-model="search.time_type" class="width_120">
          <el-option :value=0 label="充值时间"></el-option>
          <el-option :value=1 label="付款时间"></el-option>
        </el-select>
        <el-date-picker v-model="search.searchDate" value-format="YYYY-MM-DD HH:mm:ss" type="datetimerange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" ></el-date-picker>
        <span class="search-box-title">订单号：</span>
        <el-input class="width_200" v-model="search.order_id" placeholder="请输入订单号" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">账号：</span>
        <el-input v-model="search.account" class="width_200" placeholder="请输入账号" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">上级账号：</span>
        <el-input v-model="search.agent_id" class="width_200" placeholder="请输入上级账号" size="medium" clearable></el-input>
      </div>
      <!-- <div class="search-box">
        <span class="search-box-title">精准：</span>
        <el-checkbox v-model="search.checked" :true-label="1" :false-label="0" size="medium"></el-checkbox>
      </div> -->
      <div class="search-box">
        <span class="search-box-title">状态：</span>
        <el-select v-model="search.type_id"  class="width_120" filterable placeholder="全部" size="medium" clearable>
          <el-option v-for="item in typeArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <span class="search-box-title">充值项目：</span>
        <el-select v-model="search.buy_type"  class="width_150" filterable placeholder="全部" size="medium" clearable>
          <el-option :key="-1" label="全部" :value="-1"></el-option>
          <el-option :key="0" label="钱包充值" :value="0"></el-option>
          <el-option :key="1" label="购买限时会员" :value="1"></el-option>
          <el-option :key="2" label="购买永久会员" :value="2"></el-option>
          <el-option :key="3" label="购买指定蜜桃" :value="3"></el-option>
        </el-select>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="getList()">查询</el-button>
      </div>
    </div>
    <el-table :data="tableData" :header-cell-style="{background:'#F7F8FA'}">
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="order_id" label="订单号" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="account" label="账号" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="nickname" label="昵称" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="agent_account" label="上级账号" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <div>{{scope.row.agent_account == "" ? "无上级" : scope.row.agent_account}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="x" label="充值时间/付款时间" align="center" :show-overflow-tooltip="true" width="300px">
        <template #default="scope">
          <div>{{scope.row.submit_time+ "/" +scope.row.pay_time}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="buy_type" label="充值项目" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <div>{{scope.row.buy_type == ""?scope.row.pay_item:scope.row.buy_type}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="price" label="充值金额" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="shop_name" label="商户" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="pass_name" label="通道" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="pay_type" label="充值方式" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="status" label="状态" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <div v-if="scope.row.status == 0" style="color:#333333">发起支付</div>
          <div v-if="scope.row.status == 1" style="color:#33C933">支付成功</div>
          <div v-if="scope.row.status == 2" style="color:#ff9900">强制支付</div>
          <div v-if="scope.row.status == 3" style="color:#F33333">支付失败</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100px" align="center">
        <template #default="scope">
          <span class="operation" v-if="scope.row.status == 0 && new Date(scope.row.submit_time).getTime() + 86400000 > new Date().getTime()"
            @click="checkOrder(scope.row.id)">订单查询</span>
        </template>
      </el-table-column>
    </el-table>
    <page :pager="pager" @query="getList()" />
  </div>
</template>

<script>
import { getList, checkOrder } from "@/api/finance/recharge";
import page from "@/components/page";
export default {
  name: "financeRecharge",
  components: {
    page,
  },
  data() {
    return {
      pager: { total: 0, page: 1, rows: 10 },
      search: {
        //搜索
        time_type:0,
        searchDate:null,
        order_id: "", //订单号
        account: "", //账号
        agent_id: "", //上级账号
        type_id: -1, //状态id
        buy_type: -1, // 充值项目
        // checked: 1, //是否精确搜索
      },
      tableData: [], //数据
      typeArr: [
        { label: "全部", value: -1 },
        { label: "发起支付", value: 0 },
        { label: "支付成功", value: 1 },
        { label: "强制支付", value: 2 },
        { label: "支付失败", value: 3 },
      ], //状态数组
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    //获取数据
    getList() {
      if (!this.search.type_id && this.search.type_id !== 0) {
        this.search.type_id = -1;
      }
      if (!this.search.buy_type && this.search.buy_type !== 0) {
        this.search.buy_type = -1;
      }
      var bdate = this.search.searchDate && this.search.searchDate.length > 0?this.search.searchDate[0]:""
      var edate = this.search.searchDate && this.search.searchDate.length > 0?this.search.searchDate[1]:""
      getList([
        { key: "page", val: this.pager.page },
        { key: "row", val: this.pager.rows },
        { key: "order_id", val: this.search.order_id },
        { key: "account", val: this.search.account },
        { key: "agent_account", val: this.search.agent_id },
        { key: "status", val: this.search.type_id },
        { key: "buy_type", val: this.search.buy_type },
        { key: "time_type", val: this.search.time_type },
        { key: "bdate", val: bdate},
        { key: "edate", val: edate },
      ])
        .then((res) => {
          if (res.code == 0) {
            console.log(res);
            this.pager.total = res.data.total;
            this.tableData = res.data.list;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 订单查询
    checkOrder(id) {
      checkOrder([{ key: "id", val: id }]).then((res) => {
        if (res.code == 0) {
          this.$message({ message: res.data });
          this.getList();
        } else {
          this.$message({ message: res.msg, type: "error" });
        }
      });
    },
  },
};
</script>

<style>
</style>